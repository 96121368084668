import React from "react";
import "./css/Footer.css";

const Footer = () => {
  const handleOpenAboutPage = (e) => {
    e.preventDefault();
    window.open("/about", "_blank", "noopener,noreferrer");
  };
  const handleOpencontact = (e) => {
    e.preventDefault();
    window.open("/contact-us", "_blank", "noopener,noreferrer");
  };
  const handleOpenprivacy = (e) => {
    e.preventDefault();
    window.open("/privacy-policy", "_blank", "noopener,noreferrer");
  };
  const handleOpenTerm = (e) => {
    e.preventDefault();
    window.open("/terms-of-use", "_blank", "noopener,noreferrer");
  };

  return (
    <footer className="footer">
      <p>
        <a href="/terms-of-use" onClick={handleOpenTerm}>
          Terms of Use
        </a>
        <span className="footer-separator">|</span>
        <a href="/privacy-policy" onClick={handleOpenprivacy}>
          Privacy Policy
        </a>
        <span className="footer-separator">|</span>
        <a href="/contact-us" onClick={handleOpencontact}>
          Contact Us
        </a>
        <span className="footer-separator">|</span>
        <a href="/about" onClick={handleOpenAboutPage}>
          About
        </a>
      </p>
      <hr className="footer-divider" />
      <p>© 2024 GeoSpy: Ai. All rights reserved</p>
    </footer>
  );
};

export default Footer;
