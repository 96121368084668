import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./css/Contact.css";

const ContactPage = () => {
  return (
    <>
      <Header />
      <div className="contact-container">
        <div className="contact-content">
          <h1>Contact Us</h1>
          <p>
            Thank you for visiting our intelligent AI photo address recognition
            platform - GeoSpy! If you have any questions, suggestions, or need
            help, we are always happy to provide support. Here is our contact
            information, please feel free to contact us.
          </p>

          <h2>Contact Information</h2>
          <p>
            <strong>E-mail:</strong>{" "}
            <a href="mailto:developer@videomaker.cool">
              developer@videomaker.cool
            </a>
          </p>

          <h2>Notes</h2>
          <ul>
            <li>
              In order to solve your problem faster, please provide as much
              detailed information as possible when contacting us, including
              your name, contact information, problem description, etc.
            </li>
            <li>
              We will do our best to respond to your message as soon as
              possible, but please understand that response times may vary due
              to workload and time differences.
            </li>
            <li>
              Please ensure that the information you provide is true and valid
              so that we can get in touch with you.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
