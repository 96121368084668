import React from "react";
import "./css/PrivacyPolicy.css";
import Footer from "./Footer";
import Header from "./Header";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="privacy-container">
        <div className="privacy-content">
          <h1>Privacy Policy</h1>
          <p>
            Welcome to our intelligent AI recognition photo address website
            (hereinafter referred to as the "Website") - GeoSpy. We value your
            privacy and are committed to protecting your personal information.
            This Privacy Policy explains how we collect, use, disclose and
            protect your information.
          </p>

          <h2>1. Information we collect</h2>
          <h3>1.1 The information you provide</h3>
          <p>
            Uploaded content: photos, addresses, descriptions, and accompanying
            metadata uploaded on the website. You can rest assured that camera
            images, addresses, descriptions, and other information will not be
            transmitted to the internet, and all image information will be
            processed locally on your device.
          </p>

          <h3>1.2 Automatically collected information</h3>
          <p>
            Log data: We automatically collect information about your visit and
            use of the website, including your IP Address, browser type, access
            time, referring website address, and the pages you visit. The
            collected information (such as IP Address, browser type, ISP, etc.)
            does not contain any information that can directly identify
            individuals.
          </p>
          <p>
            Device Information: We may collect information about the device you
            use to access the Site, including device type, operating system and
            version, unique Device ID, and mobile network information.
          </p>

          <h2>2. How we use your information</h2>
          <p>
            We will use the information we collect for the following purposes:
          </p>
          <ul>
            <li>
              Provide and maintain services: Ensure the normal operation of the
              website, including processing your requests, operating and
              maintaining accounts.
            </li>
            <li>
              Improve and optimize services: Analyze user behavior and trends,
              conduct research and development, and enhance User Experience and
              service quality.
            </li>
            <li>
              Personalized Services: Provide personalized content and
              recommendations based on your interests and usage patterns.
            </li>
            <li>Communication: Contact us to answer your questions.</li>
            <li>Other: Provide other support and service support</li>
          </ul>

          <h2>3. Cookies</h2>
          <p>
            We use cookies and similar tracking technologies to track a user's
            activity on the Service and to save certain information. Cookies are
            small data files stored on a user's device that are used to collect
            and track information to improve and analyze our Service. Users can
            choose to decline all cookies or indicate when cookies are sent, but
            please note that this may result in the inability to use certain
            features of the Service. We use cookies, web beacons and similar
            technologies to collect information, enhance your User Experience,
            analyze usage patterns, manage advertising, remember your
            preferences and registration information (if applicable) and tailor
            the content and advertising provided through the website to your
            preferences.
          </p>
          <p>
            When you use our website, we will ask for your consent to the use of
            cookies in accordance with this privacy policy. If you do not want
            us to store any cookies on your computer, you can choose "Reject
            Cookies" or prevent this from happening by changing your browser
            settings to "Reject Cookies". However, we need to inform you that if
            you reject cookies, you may not be able to use some functions within
            the website.
          </p>

          <h2>4. Information disclosure</h2>
          <p>
            We may release certain data as required by law or necessary to
            enforce the Terms of Service and other agreements. In addition, we
            may also release certain data to protect our rights, property, or
            safety, our users, and others. This includes providing information
            to relevant agencies (such as police or government entities) when
            there is suspicion of illegal activity without prior notice to you.
          </p>

          <h2>5. Data security and third-party</h2>
          <p>
            We may engage third-party companies and individuals to assist us in
            providing services, performing tasks related to the services, or
            analyzing the use of the services. However, we will not disclose
            your personal information to these third parties at will. At the
            same time, we take reasonable technical and organizational measures
            to protect your information from unauthorized access, use, or
            disclosure. These measures include encryption, secure servers, and
            access control. However, no internet transmission or electronic
            storage method is completely secure, so we cannot guarantee its
            absolute security.
          </p>

          <h2>6. Third party advertising</h2>
          <p>
            We use Google Adsense to serve ads when you visit this website.
            Google Adsense may use cookies to store information about your
            visits to this and other websites (your browsing activity) in order
            to provide advertisements on our website about goods and services
            that may be of interest to you.
          </p>
          <p>
            Google Adsense: Provided by Google, Inc., which may use one or more
            "double-click DART cookies". DART cookies store and collect data on
            your computer about your browsing activities. Google may use this
            data to display ads based on your browsing activity on this website.
          </p>
          <p>
            You can opt out of Google's use of cookies by visiting:{" "}
            <a href="https://policies.google.com/technologies/ads">
              https://policies.google.com/technologies/ads
            </a>
          </p>

          <h2>7. Data retention</h2>
          <p>
            We will retain your information for as long as it is necessary to
            achieve the purpose for which it was collected, or for the retention
            period required by law, unless a longer retention period is required
            or permitted by law.
          </p>

          <h2>8. Privacy Policy Changes</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be notified to you by posting a new Privacy Policy on this
            page. It is recommended that you periodically review this Privacy
            Policy for any possible changes. Changes to this Privacy Policy will
            take effect immediately upon posting.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
