import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./css/CookieConsent.css";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = Cookies.get("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set("cookieConsent", "accepted", { expires: 365 });
    setIsVisible(false);
  };

  const handleDecline = () => {
    Cookies.set("cookieConsent", "declined", { expires: 365 });
    setIsVisible(false);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <img src="/img/b1.webp" alt="cooki-banner" className="cookie-banner-bg" />
      <div className="cookie-banner-content">
        <p>
          Our website uses cookies and other technologies to run this website,
          improve its operations and send you advertising and other marketing
          materials. To learn more about how we use cookies, please see our
          <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
            {" "}
            privacy policy
          </a>
          .
        </p>
        <div className="cookie-banner-buttons">
          <div className="decline-btn">
            <button onClick={handleDecline}>Reject Cookies</button>
          </div>
          <div className="allow-btn">
            <button onClick={handleAccept}>Accept Cookies</button>
          </div>
        </div>
      </div>
      <button className="cookie-banner-close" onClick={handleClose}>
        <img src="/img/close-co.png" alt="Close" />
      </button>
    </div>
  );
};

export default CookieConsent;
