import React, { useState } from "react";
import Dropzone from "react-dropzone";
import { useNavigate } from "react-router-dom";
import "./css/Main.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {
  const [inputs, setInputs] = useState([""]);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");
  const [addedInputs, setAddedInputs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleAddInput = () => {
    if (inputs[inputs.length - 1].trim() === "") {
      toast.error("Please add Country/City.");
      return;
    }
    setAddedInputs([...addedInputs, ...inputs]);
    setInputs([""]);
  };

  const handleInputChange = (index, value) => {
    const newInputs = inputs.slice();
    newInputs[index] = value;
    setInputs(newInputs);
  };

  const handleClearInput = (index) => {
    const newInputs = [...inputs];
    newInputs[index] = "";
    setInputs(newInputs);
  };

  const handleDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const handleRemoveFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const handleRemoveAddedInput = (index) => {
    const newAddedInputs = addedInputs.filter((_, i) => i !== index);
    setAddedInputs(newAddedInputs);
  };

  const handleSubmit = async () => {
    console.log("Inputs:", inputs);
    console.log("Added Inputs:", addedInputs);
    console.log("Description:", description);

    const nonEnglishRegex = /[^a-zA-Z\s]/;
    const containsNonEnglish = (input) => nonEnglishRegex.test(input);

    if (
      addedInputs.some(containsNonEnglish) ||
      containsNonEnglish(description)
    ) {
      toast.error("Sorry, we only support English.");
      return;
    }

    if (files.length === 0) {
      toast.error("Please upload a photo first.");
      return;
    }

    setIsLoading(true);

    const userPrompt = `
    Please provide a response in the following JSON format:

    {
      "photoInterpretation": "string",
      "suggestionLocations": [
        {
          "country": "string",
          "city": "string",
          "state": "string",
          "explanation": "string"
        },
        ...
      ]
    }
    
    I have a photo that most likely shows a place or building. Analyze the content of the image and try to determine the location or building that is depicted. Here are some possible places: ${addedInputs.join(
      ", "
    )}. ${description}. Please describe what you recognize in the photo, analyze the location, and suggest more than or equal to 5 likely places with brief explanations (max 20 words each). If you can't suggest any locations, summarize the additional information you need in 30 words. Reply in plain JSON format.
    `;

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("image", file);
    });

    console.log("Form Data:", formData);

    try {
      const response = await fetch(
        "https://api.developer-78b.workers.dev/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.status === 413) {
        toast.error("Sorry, we don't support images larger than 15MB.");
        setIsLoading(false);
        return;
      }

      if (response.ok) {
        const data = await response.json();
        const base64Image = data.base64Image;
        const imageUrl = `data:image/jpeg;base64,${base64Image}`;

        const requestBody = {
          model: "google/gemini-pro-vision",
          messages: [
            {
              role: "user",
              content: [
                {
                  type: "text",
                  text: userPrompt,
                },
                {
                  type: "image_url",
                  image_url: { url: imageUrl, detail: "low" },
                },
              ],
            },
          ],
        };

        console.log("requestBody:", requestBody);

        const apiResponse = await fetch(
          "https://openrouter.ai/api/v1/chat/completions",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_OPENROUTER_API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (apiResponse.status === 413) {
          toast.error("Sorry, we don't support images larger than 15MB.");
          setIsLoading(false);
          return;
        }

        const result = await apiResponse.json();
        console.log("API Response:", result);

        if (
          !result.choices ||
          !result.choices[0] ||
          !result.choices[0].message ||
          !result.choices[0].message.content
        ) {
          throw new Error("Unexpected API response format");
        }

        const responseContent = result.choices[0].message.content;
        console.log("Response Content:", responseContent);

        let parsedContent;
        try {
          const jsonString = responseContent.replace(/```json|```/g, "").trim();
          parsedContent = JSON.parse(jsonString);
        } catch (error) {
          throw new Error(
            `Failed to parse response content as JSON: ${error.message}`
          );
        }

        const {
          photoInterpretation: interpretation,
          suggestionLocations: locations,
        } = parsedContent;
        if (!Array.isArray(locations) || locations.length === 0) {
          throw new Error("Expected at least one suggestion location");
        }

        console.log("Interpretation:", interpretation);
        console.log("Locations:", locations);

        setIsLoading(false);
        navigate("/result", {
          state: {
            interpretation,
            locations,
            images: [imageUrl],
          },
        });
      } else {
        console.error("Error uploading image:", response.statusText);
        toast.error("Error uploading image.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error uploading image:", error.message);
      toast.error("Check your internet.");
      setIsLoading(false);
    }
  };

  return (
    <main className="main">
      <ToastContainer />
      {isLoading && (
        <div className="loading-page">
          <div className="loading-content">
            <div className="loading-spinner"></div>
            <p>Loading...</p>
          </div>
        </div>
      )}
      <Dropzone
        onDrop={handleDrop}
        noClick
        noKeyboard
        accept={{ "image/*": [] }}
      >
        {({ getRootProps, getInputProps, open }) => (
          <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} accept="image/*" />
            <img
              src="/img/add-m.png"
              alt="Upload"
              className="upload-image"
              onClick={open}
            />
            <h2 className="add-photo-title">Add Photo</h2>
            <p className="upload-instructions desktop">
              Press or drag here to upload a photo
            </p>
            <p className="upload-instructions mobile">
              Select photo from gallery / Take a photo
            </p>
            {files.map((file, index) => (
              <div key={index} className="file-preview">
                <img src={URL.createObjectURL(file)} alt="preview" />
                <button
                  onClick={() => handleRemoveFile(file)}
                  className="cancel-button"
                >
                  <img src="/img/close2.png" alt="Clear" />
                </button>
              </div>
            ))}
          </div>
        )}
      </Dropzone>
      <div className="input-container">
        <div className="input-group">
          <div className="label-row">
            <label>
              <img src="/img/CountryCity.png" alt="icon" />
              Country/City
            </label>
          </div>
          <p className="description">
            Please enter the country/city where you think the picture may be
            located?
          </p>
          <div className="input-all">
            {inputs.map((input, index) => (
              <div className="input-wrapper" key={index}>
                <input
                  type="text"
                  value={input}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  className="input-field"
                  placeholder="Enter"
                />
                {input && (
                  <button
                    onClick={() => handleClearInput(index)}
                    className="clear-button"
                  >
                    <img src="/img/close.png" alt="Clear" />
                  </button>
                )}
              </div>
            ))}
            <div onClick={handleAddInput} className="add-input-button">
              <img src="/img/add2.png" alt="Clear" />
            </div>
          </div>
        </div>
      </div>

      <div className="added-inputs">
        {addedInputs.map((input, index) => (
          <div key={index} className="added-input">
            <img className="added-input-img" src="/img/gps.png" alt="icon" />
            <span>{input}</span>
            <button
              onClick={() => handleRemoveAddedInput(index)}
              className="remove-added-input-button"
            >
              <img src="/img/close.png" alt="Remove" />
            </button>
          </div>
        ))}
      </div>

      <div className="textarea-group">
        <label>
          <img src="/img/Text.png" alt="icon" />
          Text
        </label>
        <textarea
          placeholder="You can enter some text information"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <button className="discover-button" onClick={handleSubmit}>
        Discover
      </button>
    </main>
  );
};

export default Main;
