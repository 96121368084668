import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./css/TermsOfUse.css";

const TermsOfUse = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <div className="terms-content">
          <h1>Terms of Use</h1>
          <p>
            Welcome to use our intelligent AI photo address recognition platform
            - GeoSpy. Please read the following user policy carefully before
            using our services. By accessing or using our website and services,
            you agree to be bound by these terms. Please read these Terms of
            Service and Conditions carefully before using our website and
            services, including but not limited to the following:
          </p>
          <p>
            These Terms constitute a contractual agreement between you and us.
            By accessing, using and/or joining (collectively, "Use") the Site,
            you understand and accept these Terms. The terms "you" or "your" as
            used in this document refer to you, any entity you represent, you or
            its representatives, successors, assigns and affiliates, and you or
            any of its devices. These Terms include limitations of liability and
            legal disclaimers that limit our liability. In other words, your use
            of the Service is at your own risk and we accept no liability and
            make no warranties of any kind, express or implied, with respect to
            the Service.
          </p>
          <p>
            These terms apply to the use of our website's network and mobile
            phones, mobile applications and other content, website and related
            services (including servers, infrastructure and facilities)
            ("Services"). The use of the Services is also subject to the SPIL
            Privacy Policy or the Mobile Application Privacy Policy.
          </p>

          <h2>Service Description</h2>
          <p>
            Our platform utilizes advanced artificial intelligence technology to
            provide users with accurate and efficient address recognition
            services by analyzing and identifying address information in photos.
            We are committed to continuously optimizing and improving our
            technology to meet the diverse needs of users.
          </p>

          <h2>Registration and Account</h2>
          <h3>1. Account Creation</h3>
          <p>
            Before using certain features, you need to create an account. You
            must provide accurate and complete information and keep it up to
            date. You are responsible for maintaining the confidentiality of the
            account and are responsible for all activities that occur under the
            account.
          </p>
          <h3>2. Account Security</h3>
          <p>
            If you discover any unauthorized use of your account or other
            security bugs, please notify us immediately. We are not responsible
            for any losses caused by your failure to comply with this security
            obligation.
          </p>
          <h3>3. Fees</h3>
          <p>
            You are fully responsible for any fees that may arise from using
            this service through any communication service.
          </p>
          <h3>4. Unauthorized Access</h3>
          <p>
            If unauthorized access to your account occurs, please notify us
            immediately and amend your login details immediately. You are solely
            responsible for maintaining the confidentiality of your login
            details and any purchases made through your account, whether
            authorized or not. If you have provided us with contact information,
            you should keep it accurate and up to date. You may only use your
            account for non-commercial purposes.
          </p>
          <h3>5. Account Deactivation</h3>
          <p>
            If you do not use or log into your account for 180 days, we reserve
            the right to terminate and permanently deactivate your account. If
            deactivated, we are under no obligation to retain or provide any
            data or content associated with your (previous) account, or refund
            any payments made through your (previous) account, and we may allow
            other users to register and use your previous username. We are also
            under no obligation to delete any public data, content, or other
            information you upload or post on the service.
          </p>
          <h3>6. Account Termination</h3>
          <p>
            Without limiting any other remedies, if you or our website suspect
            that you have not complied with the User Terms of Service or any
            actual or suspected behavior of accessing illegal or improper use of
            the Service, our website may restrict, suspend, terminate, modify or
            delete the account or any interest in the Service or its parts. Our
            website is not obligated to compensate you for any losses or
            consequences related to this, including but not limited to any
            interests, privileges or virtual items related to your use of the
            Service.
          </p>

          <h2>Use License</h2>
          <h3>1. Grant of License</h3>
          <p>
            We grant you a non-exclusive, non-transferable, limited right to
            access, non-publicly display, and use the Service Content for
            personal and non-commercial purposes only.
          </p>
          <h3>2. Unauthorized Use</h3>
          <p>You may not use the service in any unauthorized manner.</p>
          <h3>3. Termination of License</h3>
          <p>
            This License may be terminated at our sole discretion for any
            reason, with or without prior notice. Upon termination, we may, but
            are not obligated to: (i) delete or deactivate your account or use
            of the Services, (ii) block your email and/or IP Address or
            otherwise terminate your ability to use and use the Services, and/or
            (iii) remove and/or remove any of your User Submissions. You agree
            not to use or attempt to use the Services after such termination.
            Upon termination, the grant of your right to use the Services will
            terminate, but all other portions of these Terms will survive. You
            acknowledge that we are not liable to you or any third party for
            terminating your Grant of Use.
          </p>
          <h3>4. Service Limitations</h3>
          <p>
            In addition to the terms set forth herein, your use of the Service
            shall be subject to the rules, functionality, and technical
            limitations of the Service, which may change from time to time in
            our sole discretion. You may not attempt to use the Service in any
            way that is not intended or permitted to use the Service.
          </p>

          <h2>User Generated Content</h2>
          <p>
            The content you upload or generate while using our services remains
            yours. The content you submit grants us a non-exclusive,
            irrevocable, perpetual, transferable, royalty free global license to
            publish, reproduce, distribute, and adapt that content. You must
            ensure that the content submitted does not infringe on the rights of
            third parties and waive any moral rights or privacy rights to the
            extent permitted by applicable law.
          </p>

          <h2>Advertising and Third-Party Content</h2>
          <h3>1. Advertisements</h3>
          <p>
            When we make our content available to you on the Services, we may
            display advertisements before, during, after, or around the content.
            Any transaction you make with any advertiser through our Services is
            between you and the advertiser and is subject to that advertiser's
            additional terms and conditions, if any. Our Site is not responsible
            for any losses or claims you may incur against any advertiser.
          </p>
          <h3>2. Third-Party Links</h3>
          <p>
            Our services may contain links to third-party websites, including
            links provided as automatic search results. The existence of such
            links does not imply our endorsement of these third-party websites
            or services. You acknowledge and agree that we are not responsible
            or liable for any content or other materials on these third-party
            websites.
          </p>

          <h2>Service Behavior</h2>
          <p>
            You may only use the services it provides for legal, personal,
            non-commercial purposes. Within the scope of any downloadable
            content, such content may only be downloaded for personal use and
            may not be further copied or distributed. Any downloads are subject
            to the rules accompanying that particular content.
          </p>
          <h3>1. Prohibited Actions</h3>
          <p>
            You may not engage in any behavior that we believe conflicts with
            the spirit or intent of the service, use any unauthorized
            third-party software or tools, use the service in any way that
            exceeds the rights granted to you, transmit any illegal or harmful
            content through the service, or infringe on the rights of others.
          </p>
          <ul>
            <li>
              Engage in any behavior that our website believes conflicts with
              the spirit or intent of the service.
            </li>
            <li>
              Use cheating, vulnerability exploitation, automation software,
              robots, hackers, modules, or any unauthorized third-party software
              designed to modify or interfere with the service.
            </li>
            <li>
              Use the service in a way that exceeds the rights granted to you.
            </li>
            <li>
              Use the service for any data mining, robotics, or similar data
              collection and extraction tools on the content; any part of the
              framework service.
            </li>
            <li>
              Without our prior written consent, copy, adapt, display, modify,
              translate, port, publish, (sub) license, transfer, or otherwise
              distribute or exploit the content available on the service.
            </li>
            <li>
              Reverse engineering, recompiling, disassembling, deciphering or
              otherwise attempting to obtain the source code of any underlying
              software or other intellectual property used to provide the
              Services, or using any method not expressly permitted by our Site
              to obtain any information.
            </li>
            <li>
              Without our prior written consent, provide any form of advertising
              or promotion on the service.
            </li>
            <li>
              Transmit any unsolicited bulk communication (including email and
              instant messaging) directly or indirectly through the service.
            </li>
            <li>
              Interfere with any other party's use and enjoyment of the
              Services.
            </li>
            <li>
              Use the service in a way that may damage, disable, or overload our
              servers or network.
            </li>
            <li>
              At the same time, you may not submit or transmit any information,
              content, or materials through the service, or otherwise engage in
              any of the following activities:
              <ul>
                <li>
                  Content that is illegal, threatening, abusive, harassing,
                  defamatory, deceptive, fraudulent, violates the privacy of
                  others, distorts, contains explicit or graphic descriptions or
                  depictions of sexual activity.
                </li>
                <li>
                  Infringes or infringes the rights of others, including but not
                  limited to patents, trademarks, trade secrets, copyrights,
                  publicity or other proprietary rights.
                </li>
                <li>
                  Impersonate any person, business or entity, including our
                  website and its employees and agents.
                </li>
                <li>
                  Contains viruses or any other computer code, file or program
                  that interrupts, destroys or limits the functionality of any
                  software, hardware or telecommunications equipment, or
                  otherwise permits unauthorized use of a computer or computer
                  network.
                </li>
                <li>
                  Encourage behavior that may constitute a criminal offense or
                  give rise to civil liability.
                </li>
                <li>
                  Harming, harassing, degrading, or intimidating individuals or
                  groups based on religion, gender, sexual orientation, race,
                  ethnicity, age, or disability.
                </li>
                <li>
                  Otherwise violate these User Terms of Service, guidelines or
                  any policies posted on the Service.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Our website reserves the right to determine whether it believes it
            violates the Terms of Service or exceeds the intent or spirit of
            these Terms of Service, and reserves the right to take appropriate
            action, including terminating your account and prohibiting you from
            using all or part of the Services.
          </p>
          <p>
            Thank you for using our intelligent AI photo address recognition
            platform. We are committed to providing you with the best service
            experience.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfUse;
