import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/AllMain";
import ResultPage from "./components/Result";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import ContactPage from "./components/Contact";
import AboutPage from "./components/About";
import { analytics } from "./firebase";
import CookieConsent from "./components/CookieConsent";

const App = () => {
  useEffect(() => {
    if (analytics) {
      import("firebase/analytics").then(({ logEvent }) => {
        logEvent(analytics, "page_view"); // Use the imported logEvent function
      });
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/result" element={<ResultPage />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Routes>
      <CookieConsent />
    </Router>
  );
};

export default App;
