import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./css/Result.css";
import Header from "./Header";
import Footer from "./Footer";
import CookieConsent from "./CookieConsent";

const ResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (!location || !location.state) {
    console.error("Location state is undefined");
    return (
      <div>
        <h1>Error</h1>
        <p>Location state is undefined. Please go back and try again.</p>
        <button onClick={() => navigate("/")}>Go Back</button>
      </div>
    );
  }

  const { interpretation, locations = [], images } = location.state;

  return (
    <>
      <Header />
      <div className="result-container">
        <div className="content-wrapper">
          {images && images.length > 0 && (
            <div className="image-container">
              <img src={images[0]} alt="Uploaded" className="uploaded-image" />
            </div>
          )}
          <div className="interpretation-container inter-card">
            <p className="interpretation">{interpretation}</p>
          </div>
          <div className="locations-container">
            {locations.length > 0 ? (
              locations.map((loc, index) => (
                <div key={index} className="location-card">
                  <div className="location-card-content">
                    <div className="location-details">
                      <div className="location-details-row">
                        <div className="location-image-container">
                          <img src="/img/gps.png" alt="icon" />
                        </div>
                        <p className="count">Country: {loc.country || "--"}</p>
                      </div>
                      <p className="detail">State: {loc.state || "--"}</p>
                      <p className="detail">City: {loc.city || "--"}</p>
                      <p className="detail">
                        Explanation: {loc.explanation || "--"}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No possible locations found</p>
            )}
          </div>
        </div>
      </div>
      <CookieConsent />
      <Footer />
    </>
  );
};

export default ResultPage;
