import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./css/About.css";

const AboutPage = () => {
  return (
    <>
      <Header />
      <div className="about-container">
        <div className="about-content">
          <h1>Welcome to GeoSpy</h1>
          <p>
            Welcome to our intelligent AI photo address recognition platform -
            GeoSpy! We are committed to providing users with the most advanced
            technology solutions, making your photo management simple and
            efficient through intelligent photo address recognition function.
          </p>

          <h2>Our Vision</h2>
          <p>
            To become the world's leading photo address recognition platform,
            providing users with excellent services to help them better manage
            and utilize address information in photos.
          </p>

          <h2>Our Mission</h2>
          <p>
            1. Enhance User Experience: With an intuitive and user-friendly
            interface and powerful features, ensure that users can easily
            recognize and manage address information in photos.
          </p>
          <p>
            2. Driving technology frontiers: Continuously adopting the latest
            artificial intelligence and Machine Learning technologies to improve
            our recognition accuracy and efficiency.
          </p>
          <p>
            3. Ensure data security: Strictly abide by the Privacy Policy to
            ensure the security and privacy of user data are fully protected.
          </p>

          <h2>Our Team</h2>
          <p>
            We have a passionate and innovative team, including technical
            experts, Product Managers, User Experience Designers, etc. The team
            members have rich experience in fields such as artificial
            intelligence, Computer Vision, and data processing, and are
            committed to transforming complex technologies into practical and
            efficient solutions.
          </p>

          <h2>Our Technology</h2>
          <p>
            We use the most advanced algorithms and Machine Learning to discover
            precise photo locations. Our core technologies include:
          </p>
          <ul>
            <li>
              Image Processing and Computer Vision: Accurately extract and
              analyze address information from photos through deep learning
              algorithms.
            </li>
            <li>
              Natural language processing (NLP): Understanding and processing
              text information in photos to further improve recognition
              accuracy.
            </li>
            <li>
              Big Data Analysis: Utilizing Big data technology to continuously
              optimize recognition models, improve system performance and User
              Experience.
            </li>
          </ul>

          <h2>Operating Steps</h2>
          <ol>
            <li>Upload images.</li>
            <li>
              Support entering the location where you may think this image is
              located.
            </li>
            <li>Support input prompt words.</li>
            <li>
              Click the "Discover" button to discover the geographic location of
              the image.
            </li>
          </ol>

          <p>
            Thank you for choosing GeoSpy - Intelligent AI Photo Address
            Recognition Platform. We will continuously optimize our algorithms
            to provide users with the best quality products and services.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
