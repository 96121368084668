import React from "react";
import "./css/Header.css";

// Function to sanitize subtitle input
const sanitizeSubtitle = (text) => text.replace(/\s+/g, " ");

const Header = () => {
  const subtitle =
    "Depend on AI, which uses advanced algorithms and machine learning to discover precise photo locations.";

  return (
    <header className="header">
      <img src="/img/banner.webp" alt="Background" className="header-image" />
      <div className="header-content">
        <img
          src="/img/apple-touch-icon.png"
          alt="Logo"
          className="logo-image"
        />
        <h1 className="title">GeoSpy AI</h1>
        <h2 className="subtitle">{sanitizeSubtitle(subtitle)}</h2>
      </div>
    </header>
  );
};

export default Header;
