import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCKYysb5eszG5WEt11vUIU-V3SG9zlHG-c",
  authDomain: "geospy-e7b0f.firebaseapp.com",
  projectId: "geospy-e7b0f",
  storageBucket: "geospy-e7b0f.appspot.com",
  messagingSenderId: "33877145203",
  appId: "1:33877145203:web:c04a500943390f00d61c06",
  measurementId: "G-EXHPEZD81V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };
